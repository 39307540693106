import { styled } from "styled-components";
import { Spinner, Text } from "@fidelix/fx-miranda";
import { Trans } from "@lingui/macro";

export function LoadingView() {
  return (
    <Container>
      <Text variant="subtitle1">
        <Trans>Loading</Trans>
      </Text>
      <Spinner size="medium" />
    </Container>
  );
}

const Container = styled.div`
  height: 150px;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.medium}px;
`;
