import { styled } from "styled-components";
import { Trans } from "@lingui/macro";
import { Button, Text } from "@fidelix/fx-miranda";

type Props = {
  onReset: () => void;
};

export function ErrorView({ onReset }: Props) {
  return (
    <Container>
      <Text variant="title1">
        <Trans>There was an error!</Trans>
      </Text>
      <Text variant="bodyBold">
        <Trans>Something went wrong while loading application data.</Trans>
      </Text>
      <Button onPress={onReset}>
        <Trans>Try again</Trans>
      </Button>
    </Container>
  );
}
// Use position absolute here since this error view is misaligned in certain views
const Container = styled.div`
  position: absolute;
  left: 0px;
  height: 90%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(p) => p.theme.spacing.medium}px;
`;
