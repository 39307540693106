import { useDeferredValue } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { useSpinDelay } from "spin-delay";
import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseQueryOptions,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";

/**
 * A version of `useSuspenseQuery` that defers the query key to avoid triggering
 * the suspense fallback when the query key changes, eg. when updating query
 * filters like pagination, sorting, etc.
 *
 * You most likely will never have to use the basic `useSuspenseQuery` from
 * `@tanstack/react-query`. Either use the regular `useQuery` when you don't
 * need to use suspense or use this `useSuspenseQueryDeferred` when you want to
 * use suspense.
 */
export function useSuspenseQueryDeferred<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseSuspenseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  /**
   * We need to memoize the query key in order to provide a stable reference
   * to the `useDeferredValue` hook. This is important because the `useDeferredValue`
   * uses `Object.is` to compare the previous and next value so without memoization
   * the `queryKey` array would always be considered different which would cause
   * unnecessary background re-renders.
   */
  const queryKey = useDeepCompareMemo(
    () => options.queryKey,
    [options.queryKey],
  );

  /**
   * Deferring the query key allows us to avoid triggering the suspense fallback
   * when the query key changes. Without this every time we update any query key
   * param (eg. pagination, filters, etc.) the suspense fallback would be triggered
   * which would replace the page content with the loading spinner for a moment.
   * Instead of this we want to only show suspense fallback on initial load and
   * on subsequent fetches we want to show an inline pending indicator,
   * eg. a shimmer animation in the `Table` component.
   */
  const deferredQueryKey = useDeferredValue(queryKey);

  const query = useSuspenseQuery({
    ...options,
    queryKey: deferredQueryKey,
  });

  /**
   * Add a special flag to indicate that the query is suspending.
   * This can be used to display an inline loading indicator.
   * We utilize `useSpinDelay` hook to avoid unwanted spinner flickering
   * in case data loads very quickly.
   */
  const isSuspending = useSpinDelay(queryKey !== deferredQueryKey);

  return { ...query, isSuspending };
}

export function useSuspenseInfiniteQueryDeferred<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  options: UseSuspenseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey,
    TPageParam
  >,
) {
  const queryKey = useDeepCompareMemo(
    () => options.queryKey,
    [options.queryKey],
  );

  const deferredQueryKey = useDeferredValue(queryKey);

  const query = useSuspenseInfiniteQuery({
    ...options,
    queryKey: deferredQueryKey,
  });

  const isSuspending = useSpinDelay(queryKey !== deferredQueryKey);

  return { ...query, isSuspending };
}
