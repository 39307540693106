import {
  infiniteQueryOptions,
  queryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { assetsApiRequest } from "~api/api-request";
import { useRequiredParams } from "~utils/url";
import { ApiResponse, ApiResponsePaginated } from "~api/api-response.model";
import { Device } from "./device.model";
import { QueryOptions } from "../utils/consts";

type DeviceListParams = {
  levelId?: number;
  companyId: number;
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  order?: string;
  nested?: string;
  status?: number[];
  deviceIds?: number[];
  externalIds?: string[];
};

type InfiniteDeviceListParams = Omit<DeviceListParams, "page">;

export const deviceQueries = {
  all: (params: DeviceListParams, options?: QueryOptions) =>
    queryOptions({
      queryKey: ["devices", params],
      queryFn: ({ signal }) => fetchDevices(params, signal),
      ...options,
    }),
  detail: (params: { companyId: number; id?: number }) =>
    queryOptions({
      queryKey: ["device-detail", params],
      queryFn: ({ signal }) => fetchDevice(params, signal),
    }),
  infinite: (params: InfiniteDeviceListParams) =>
    infiniteQueryOptions({
      queryKey: ["device-list", params],
      queryFn: ({ signal, pageParam }) =>
        fetchInfiniteDevices({ ...params, pageParam }, signal),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPage.devices.length === 0) {
          return undefined;
        }
        return lastPageParam + 1;
      },
    }),
};

// Queries

export function useDevicesQuery(
  params: Omit<DeviceListParams, "companyId">,
  options?: QueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(
    deviceQueries.all({ companyId: companyId, ...params }, options),
  );
}

export function useDeviceQuery(
  id?: number,
  queryOption?: { enabled: boolean },
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    ...queryOption,
    ...deviceQueries.detail({ companyId, id }),
  });
}

export function useDevicesInfiniteQuery(
  params: Omit<InfiniteDeviceListParams, "companyId">,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useInfiniteQuery(deviceQueries.infinite({ companyId, ...params }));
}

// Fetchers

async function fetchInfiniteDevices(
  {
    companyId,
    pageParam,
    ...params
  }: InfiniteDeviceListParams & { pageParam: number },
  signal?: AbortSignal,
) {
  return assetsApiRequest
    .get<
      ApiResponsePaginated<Device[]>
    >(`analytics/companies/${companyId}/devices`, { params: { ...params, page: pageParam }, signal })
    .then((resp) => ({
      devices: resp.data.data.map((d) => new Device(d)),
      pagination: resp.data.meta.pagination,
    }));
}

export async function fetchDevices(
  params: DeviceListParams,
  signal?: AbortSignal,
) {
  return assetsApiRequest
    .get<
      ApiResponsePaginated<Device[]>
    >(`analytics/companies/${params.companyId}/devices`, { params, signal })
    .then((resp) => resp.data.data.map((d) => new Device(d)));
}

async function fetchDevice(
  params: { companyId: number; id?: number },
  signal?: AbortSignal,
) {
  if (!params.id) {
    throw new Error("deviceId not defined");
  }
  return assetsApiRequest
    .get<
      ApiResponse<Device>
    >(`analytics/companies/${params.companyId}/devices/${params.id}`, { signal })
    .then((resp) => new Device(resp.data.data));
}

export async function fetchDeviceByExternalId(params: {
  companyId: number;
  externalId: string;
}) {
  return assetsApiRequest
    .get<
      ApiResponse<Device[]>
    >(`analytics/companies/${params.companyId}/devices?externalIds[]=${params.externalId}&pageSize=1&page=1`)
    .then((resp) =>
      resp.data.data.length !== 1 ? undefined : new Device(resp.data.data[0]),
    );
}
