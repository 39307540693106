import { Device } from "~device/device.model";
import { Level } from "~level/level.model";

export type MenuItems = {
  name: string;
  path: string;
  items: MenuItems[];
  ignoreHeader?: boolean;
};

export class Graphics {
  name: string;
  device: Device | null;
  level: Level | null;
  items: MenuItems[];
  id: number;

  constructor(model: Graphics) {
    this.device = model.device ? new Device(model.device) : null;
    this.level = model.level ? new Level(model.level) : null;
    this.name = model.name;
    this.items = model.items;
    this.id = model.id;
  }
}

export class GraphicsAttachment {
  publicUrl: string;
  name: string;
  constructor(data: GraphicsAttachment) {
    this.publicUrl = data.publicUrl;
    this.name = data.name;
  }
}

export class Company {
  companyId: number;
  defaultGraphicsId: number;
  defaultGraphicsPath: string | null;

  constructor(data: Company) {
    this.companyId = data.companyId;
    this.defaultGraphicsId = data.defaultGraphicsId;
    this.defaultGraphicsPath = data.defaultGraphicsPath;
  }
}
