import { ReactNode } from "react";
import { Permissions } from "../const";
import { useUserContext } from "../user/user-context";

const STORAGE_PERMISSION_KEY = "user-permissions";

export const setPermissionsTostorage = (permissions) => {
  sessionStorage.setItem(STORAGE_PERMISSION_KEY, JSON.stringify(permissions));
};

export const hasPermission = (
  permission: Permissions,
  permissions: string[],
) => {
  return permissions.includes(permission);
};

export const HasPermission = (props: {
  permission: Permissions;
  children: ReactNode;
}) => {
  const { permission, children } = props;
  const { user } = useUserContext();
  if (!user) {
    return null;
  }
  const userPermission = hasPermission(permission, user.permissions!);

  if (userPermission) {
    return children;
  }

  return null;
};
