import { queryOptions, useQuery } from "@tanstack/react-query";
import { ApiResponse } from "~api/api-response.model";
import { useRequiredParams } from "~utils/url";
import { assetsApiRequest } from "~api/api-request";
import { Company } from "./graphics.model";

type DefaultGraphicsParams = {
  companyId: number;
};

export const defaultGraphicsQuery = {
  details: (params: DefaultGraphicsParams) =>
    queryOptions({
      queryKey: ["default-graphics", params],
      queryFn: () => fetchDefaultGraphics(params),
    }),
};

export function useDefaultGraphicsQuery() {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    ...defaultGraphicsQuery.details({ companyId }),
  });
}

async function fetchDefaultGraphics(params: DefaultGraphicsParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Company>
    >(`analytics/companies/${params.companyId}/default-graphics`)
    .then((resp) => (resp.data ? new Company(resp.data.data) : null));
}
