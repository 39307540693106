import { Button, Icon, Modal, Stack, Text } from "@fidelix/fx-miranda";
import { Trans } from "@lingui/macro";
import styled from "styled-components";

const GenericModal = ({
  onClose,
  onContinue,
  title,
  text,
}: {
  onClose: () => void;
  onContinue: () => void;
  title: string;
  text: string;
}) => {
  return (
    <Modal onClose={onClose} placement="middle">
      <ModalWrapper>
        <Modal.Body>
          <Stack axis="y" spacing="large" align="center">
            <Icon icon="attentionFilled" size={48} color="primary" />
            <Text variant="title2Bold">{title}</Text>
            <Text variant="bodyBold">{text}</Text>
          </Stack>
        </Modal.Body>
      </ModalWrapper>
      <Modal.Footer>
        <Stack spacing="normal" justify="center">
          <Button variant="secondary" onPress={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onPress={onContinue}>
            <Trans>Continue</Trans>
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22.5vw;
  height: 25vh;
  min-width: 500px;
  min-height: 250px;
  text-align: center;
`;
export default GenericModal;
