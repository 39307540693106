import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Trans } from "@lingui/macro";
import { styled } from "styled-components";
import {
  Icon,
  Button as MirandaButton,
  ScrollableContainer,
  Spinner,
  Text,
} from "@fidelix/fx-miranda";
import {
  Button,
  Dialog,
  DialogTrigger,
  OverlayTriggerStateContext,
  Popover,
} from "react-aria-components";
import { useNavigatonPath } from "~utils/url";
import {
  deviceNoticationLimit,
  deviceNotificationListLimit,
} from "../utils/consts";
import { useDevicesQuery } from "./device.queries";
import { Device, DeviceStatus } from "./device.model";

const deviceTypes = [
  DeviceStatus.AuthFailure,
  DeviceStatus.Disconnected,
  DeviceStatus.Timeout,
  DeviceStatus.VersionFailure,
];

function DevicesMenu({
  devices,
  isFetching,
  isLoading,
  isError,
}: {
  devices: Device[];
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
}) {
  const navigate = useNavigate();
  const { to } = useNavigatonPath();
  const state = useContext(OverlayTriggerStateContext);

  return (
    <DevicesPopover>
      <DevicesMenuHeader>
        <Text variant="subtitle1">
          <Trans>Device disconnections ({devices.length})</Trans>
        </Text>
        {isFetching && !isLoading && <Spinner />}
      </DevicesMenuHeader>
      <DevicesMenuBody>
        {devices.length > 0 ? (
          devices.map((d) => (
            <DeviceButton key={d.id}>
              <Text variant="body">
                <Trans>Disconnection</Trans>
              </Text>
              <Text variant="bodyLight">{d.name}</Text>
            </DeviceButton>
          ))
        ) : isLoading ? (
          <EmptyContainer>
            <Spinner size="medium" />
          </EmptyContainer>
        ) : isError ? (
          <EmptyContainer>
            <Text variant="body">
              <Trans>Failed to load devices</Trans>
            </Text>
          </EmptyContainer>
        ) : (
          <EmptyContainer>
            <Text variant="body">
              <Trans>No device disconnections</Trans>
            </Text>
          </EmptyContainer>
        )}
      </DevicesMenuBody>
      <DevicesMenuFooter>
        <MirandaButton
          variant="text"
          icon="chevronRight"
          iconPlacement="right"
          onPress={() => {
            state.close();
            navigate(
              to(
                `devices?${createSearchParams({ status: `[${deviceTypes}]` }).toString()}`,
              ),
            );
          }}
        >
          <Text variant="bodyBold" color="darkPrimary">
            <Trans>Show all affected devices</Trans>
          </Text>
        </MirandaButton>
      </DevicesMenuFooter>
    </DevicesPopover>
  );
}

export function DeviceNavBarIcon() {
  const {
    data: devices = [],
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useDevicesQuery(
    {
      page: 1,
      pageSize: deviceNotificationListLimit,
      status: deviceTypes,
    },
    { refetchInterval: 30000 },
  );

  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      refetch();
    }
  };

  return (
    <Container>
      <DialogTrigger onOpenChange={onOpenChange}>
        <DeviceIcon>
          <Icon icon="connectionWifi" color="white" size={32} />
        </DeviceIcon>
        <Popover>
          <Dialog>
            <DevicesMenu
              devices={devices}
              isFetching={isFetching}
              isLoading={isLoading}
              isError={isError}
            />
          </Dialog>
        </Popover>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner size={24} />
          </SpinnerContainer>
        ) : (
          devices.length > 0 && (
            <NumberContainer>
              <Text variant="bodyExtraSmall" color="white">
                {devices.length >= deviceNoticationLimit
                  ? `${deviceNoticationLimit}+`
                  : devices.length}
              </Text>
            </NumberContainer>
          )
        )}
      </DialogTrigger>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
`;

const SmallInfoContainer = styled.div`
  aspect-ratio: 1 / 1;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  pointer-events: none;
`;

const NumberContainer = styled(SmallInfoContainer)`
  background: ${(p) => p.theme.colors.error};
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.white};
  position: absolute;
  right: 0px;
`;

const SpinnerContainer = styled(SmallInfoContainer)`
  position: absolute;
  right: 0px;
  color: ${(p) => p.theme.colors.lightNeutral};
`;
const DeviceIcon = styled(Button)`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: ${(p) => p.theme.colors.darkPrimary};
  }
`;

const DevicesPopover = styled.div`
  width: 420px;
  position: absolute;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.lightNeutral};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  transform: translateX(-400px);
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${(p) => p.theme.spacing.xlarge}px;
  justify-content: center;
`;

const DeviceButton = styled(Button)`
  width: 100%;
  display: flex;
  padding: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.xxsmall}px;
  flex-direction: column;
  &:hover {
    background: ${(p) => p.theme.colors.mutedNeutralBackground};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.primaryMuted};
  }
`;

const DevicesMenuBody = styled(ScrollableContainer)`
  max-height: 420px;
`;

const DevicesMenuHeader = styled.div`
  padding: ${(p) => p.theme.spacing.medium}px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primaryMuted};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.small}px;
`;

const DevicesMenuFooter = styled.div`
  padding: ${(p) => p.theme.spacing.medium}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${(p) => p.theme.colors.primaryMuted};
`;
