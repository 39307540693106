import { Stack, TopNavigation, TopNavigationItems } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";
import { usePathNavigator, useNavigatonPath } from "~utils/url";
import { useParams } from "react-router-dom";
import { CompanySelect } from "../company/company-select";
import { AlarmNotificationBell } from "../alarm/alarm-notification-bell";
import { NavMenu } from "./nav-menu";
import { Permissions } from "../const";
import { HasPermission, hasPermission } from "../utils/permission-helper";
import { useUserContext } from "../user/user-context";
import { DeviceNavBarIcon } from "../device/device-navbar-icon";

/**
 * Find the active route by searching which of the inputted routenames occur last in the location path
 * We have routes that have multiple occurences of our routenames for example:
 *
 * company/5/devices/4/points/588870
 * (this has both /devices and /points in it)
 *
 * And this was causing problems in the top navigation bar context. It would show Devices
 * selected eventhough we were clearly in Points
 */
function getActiveRoute(routes: string[]): string {
  return routes.reduce<{
    routeName: string;
    routeIndex: number;
  }>(
    (a, c) => {
      const idx = location.pathname.lastIndexOf(c);
      if (idx > a.routeIndex) {
        return { routeName: c, routeIndex: idx };
      }
      return a;
    },
    { routeName: "", routeIndex: 0 },
  ).routeName;
}

export function Navbar() {
  const { to } = useNavigatonPath();
  const { companyId } = useParams();
  const { withSubUrl } = usePathNavigator();
  const { user } = useUserContext();
  const activeMainRoute = getActiveRoute([
    "graphics",
    "alarms",
    "history",
    "points",
    "logs",
    "devices",
    "menus",
  ]);
  const activeSubRoute = getActiveRoute(["logs/event", "logs/alarm"]);

  const permissions = user?.permissions || [];
  const items: TopNavigationItems = [
    {
      to: to("graphics"),
      label: t`Graphics`,
      isActive: activeMainRoute === "graphics" || activeMainRoute === "menus",
      isVisible: hasPermission(Permissions.GRAPHIC_VIEW, permissions),
    },
    {
      to: to("alarms"),
      label: t`Alarms`,
      isActive: activeMainRoute === "alarms",
      isVisible: hasPermission(Permissions.ALARMS_VIEW, permissions),
    },
    {
      to: to("history"),
      label: t`History`,
      isActive: activeMainRoute === "history",
      isVisible: hasPermission(Permissions.HISTORY_VIEW, permissions),
    },
    {
      to: to("points"),
      label: t`Points`,
      isActive: activeMainRoute === "points",
      isVisible: hasPermission(Permissions.POINTS_VIEW, permissions),
    },
    {
      to: to("logs"),
      label: t`Logs`,
      isActive: activeMainRoute === "logs",
      isVisible:
        hasPermission(Permissions.EVENTLOG_VIEW, permissions) ||
        hasPermission(Permissions.ALARMLOG_VIEW, permissions),
      items: [
        {
          to: to("logs/event"),
          label: t`Event`,
          isActive: activeSubRoute === "logs/event",
          isVisible: hasPermission(Permissions.EVENTLOG_VIEW, permissions),
        },
        {
          to: to("logs/alarm"),
          label: t`Alarm`,
          isActive: activeSubRoute === "logs/alarm",
          isVisible: hasPermission(Permissions.ALARMLOG_VIEW, permissions),
        },
      ].filter((o) => o.isVisible),
    },
    {
      to: to("devices"),
      label: t`Devices`,
      isActive: activeMainRoute === "devices",
      isVisible: hasPermission(Permissions.DEVICES_VIEW, permissions),
    },
  ].filter((o) => o.isVisible);

  const menuItems = [
    {
      to: withSubUrl(`/company/${companyId}`),
      label: t`SCADA`,
    },
    {
      to: withSubUrl(`https://assets.fidelix.com/company/${companyId}`),
      label: t`Device Management`,
    },
    {
      to: withSubUrl(`https://admin.fidelix.com/${companyId}/users`),
      label: t`Access Management`,
    },
  ];

  return (
    <TopNavigation variant="primary">
      <TopNavigation.Logo menuItems={menuItems} />
      <TopNavigation.Items items={items} />
      <TopNavigation.Actions>
        <Stack axis="x" spacing="small" align="center">
          <CompanySelect />
          <HasPermission permission={Permissions.DEVICES_VIEW}>
            <DeviceNavBarIcon />
          </HasPermission>
          <HasPermission permission={Permissions.ALARMS_VIEW}>
            <AlarmNotificationBell />
          </HasPermission>
          <NavMenu />
        </Stack>
      </TopNavigation.Actions>
    </TopNavigation>
  );
}
