import { useMutation } from "@tanstack/react-query";
import { assetsApiRequest } from "../api/api-request";
import { useRequiredParams } from "../utils/url";

export const useUploadGraphicsMutation = () => {
  const { companyId, levelId } = useRequiredParams({
    companyId: "number",
    levelId: "number",
  });
  return useMutation({
    mutationFn: (formData: FormData) =>
      uploadGraphics({ companyId, levelId, formData }),
    // TODO: onError
  });
};

export const useDeleteGraphicsMutation = () => {
  return useMutation({
    mutationFn: ({
      graphicsId,
      companyId,
      levelId,
    }: {
      graphicsId: number;
      companyId: number;
      levelId: number;
    }) => deleteGraphics({ companyId, levelId, graphicsId }),
  });
};

const uploadGraphics = ({
  companyId,
  levelId,
  formData,
}: {
  companyId: number;
  levelId: number;
  formData: FormData;
}) => {
  return assetsApiRequest.post(
    `/analytics/companies/${companyId}/levels/${levelId}/graphics`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
};

const deleteGraphics = (params: {
  companyId: number;
  levelId: number;
  graphicsId: number;
}) => {
  return assetsApiRequest
    .delete(
      `analytics/companies/${params.companyId}/levels/${params.levelId}/graphics/${params.graphicsId}`,
    )
    .then((o) => o.data.data);
};
