import { useLingui } from "@lingui/react";
import { Outlet } from "react-router-dom";
import { css, styled } from "styled-components";

import { usePersistSearchParams } from "~utils/url";
import { ScrollableContainer } from "@fidelix/fx-miranda";
import { Navbar } from "./nav-bar";
import { QueryBoundary } from "./query-boundary";

export function PageLayout() {
  useLingui();
  usePersistSearchParams();

  return (
    <Layout>
      <Navbar />
      {/* NOTE: this id is used to calculate the width of the main content area */}
      <Main id="main">
        <QueryBoundary>
          <Outlet />
        </QueryBoundary>
      </Main>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${(p) => p.theme.colors.mutedNeutralBackground};
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.white};
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

export const MainPanelLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${(p) => p.theme.spacing.large}px;
  padding-left: ${(p) => p.theme.spacing.medium}px;
  padding-right: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.medium}px;
`;

export const SidePanelLayout = styled(ScrollableContainer)<{
  $showBorder?: boolean;
  $isScrollable?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.theme.spacing.large}px;
  padding-left: ${(p) => p.theme.spacing.medium}px;
  padding-right: ${(p) => p.theme.spacing.medium}px;
  gap: ${(p) => p.theme.spacing.medium}px;
  min-width: 20%;
  border-right: ${(p) =>
    p.$showBorder ? `1px solid ${p.theme.colors.lightNeutral}` : "none"};
  overflow-y: hidden;

  ${(p) =>
    p.$isScrollable === true &&
    css`
      overflow-y: auto;
      // Calculate the height of the scrollable portion
      // 72px is the height of top navigation bar
      // TODO: Instead of hardcoding the height of the navigation bar maybe
      //       get a ref to the navigation bar and find out the height from there
      max-height: ${(p) => window.innerHeight - 72 - p.theme.spacing.xxlarge}px;
      scrollbar-gutter: stable;
      background-clip: padding-box;
    `}
`;
