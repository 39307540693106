import { queryOptions, useQuery } from "@tanstack/react-query";
import { assetsApiRequest } from "~api/api-request";
import { ApiResponse } from "~api/api-response.model";
import { useRequiredParams } from "~utils/url";
import { Graphics, GraphicsAttachment } from "./graphics.model";
import { QueryOptions } from "../utils/consts";
import { fetchDevices } from "../device/device.queries";
import { fetchLevelGraphics } from "../level/level.queries";
import { searchInGraphicItems } from "./graphics.utils";

type GraphicsParams = {
  companyId: number;
  graphicsId: number;
};

type GraphicsIdParams = {
  companyId: number;
  deviceId: number;
  levelId: number;
  path: string;
};
export const graphicsQueries = {
  one: (params: GraphicsParams) =>
    queryOptions({
      queryKey: ["devices-graphics", params],
      queryFn: ({ signal }) => fetchGraphics(params, signal),
    }),
  attachments: (params: GraphicsParams, options?: QueryOptions) =>
    queryOptions({
      queryKey: ["graphics-attachments", params],
      queryFn: ({ signal }) => fetchGraphicsAttachments(params, signal),
      ...options,
    }),
  idByLevelOrDevice: (params: GraphicsIdParams, options?: QueryOptions) =>
    queryOptions({
      queryKey: ["graphics-id", params],
      queryFn: () => fetchGraphicsIdByLevelOrDevice(params),
      ...options,
    }),
};

export function useGraphicsQuery({
  graphicsId,
  enabled = true,
}: {
  graphicsId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    enabled,
    ...graphicsQueries.one({ companyId, graphicsId }),
  });
}

export function useGraphicsAttachmentsQuery(
  graphicsId: number,
  options?: QueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(
    graphicsQueries.attachments({ companyId, graphicsId }, options),
  );
}

export function useGraphicsIdByPathQuery(
  params: Omit<GraphicsIdParams, "companyId">,
  options?: QueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(
    graphicsQueries.idByLevelOrDevice({ ...params, companyId }, options),
  );
}

async function fetchGraphics(params: GraphicsParams, signal?: AbortSignal) {
  return assetsApiRequest
    .get<
      ApiResponse<Graphics>
    >(`analytics/companies/${params.companyId}/graphics/${params.graphicsId}`, { signal })
    .then((resp) => new Graphics(resp.data.data));
}

async function fetchGraphicsAttachments(
  params: GraphicsParams,
  signal?: AbortSignal,
) {
  return assetsApiRequest
    .get<
      ApiResponse<GraphicsAttachment[]>
    >(`analytics/companies/${params.companyId}/graphics/${params.graphicsId}/attachments`, { signal })
    .then((resp) =>
      resp.data.data.map((attachment) => new GraphicsAttachment(attachment)),
    );
}

async function fetchGraphicsIdByLevelOrDevice(params: GraphicsIdParams) {
  const { companyId, deviceId, levelId, path } = params;
  const device = await fetchDevices({
    companyId,
    pageSize: 1,
    page: 1,
    deviceIds: [deviceId],
  });
  const level = await fetchLevelGraphics({ companyId, levelId });
  const graphic = device[0].graphics.find(
    (graphic) =>
      searchInGraphicItems({ items: graphic.items, searchPath: path }) ||
      level.find((graphic) =>
        searchInGraphicItems({ items: graphic.items, searchPath: path }),
      ),
  );

  return graphic?.id;
}
