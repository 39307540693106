import { Navigate, createBrowserRouter } from "react-router-dom";
import { GraphicsRoute } from "~graphics/graphics.route";
import { RootRouteError } from "~common/root-route-error";
import { ProtectedRoute } from "~common/protected-route";
import { PageLayout } from "~common/page-layout";
import { restoreSearchParamsLoader } from "~utils/url";
import { RootRoute } from "./root";

/**
 * This is the way to define routes with React Router v6.4 and higher.
 * It enables the data APIs like loaders, actions, fetchers and more.
 * See: https://reactrouter.com/en/main/routers/create-browser-router
 */
export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <RootRouteError />,
    children: [
      /**
       * We dont' render anything in the root route `/` so we want to redirect
       * the user to the protected `/company` url segment that will handle the
       * automatic redirect to the first available company if the user is authorized.
       */
      {
        index: true,
        element: <Navigate to="company" replace />,
      },
      {
        path: "auth/callback",
        lazy: () => import("./authenticated-callback"),
      },
      {
        path: "auth/unauthorized",
        lazy: () => import("./unauthorized"),
      },
      {
        path: "logout",
        lazy: () => import("./logout"),
      },
      /**
       * All the main routes of the app are under the `/company` url segment.
       */
      {
        path: "company",
        element: <ProtectedRoute />,
        children: [
          {
            path: ":companyId",
            element: <PageLayout />, // this wraps all child routes
            children: [
              {
                path: "dashboard",
                lazy: () => import("../dashboard/dashboard.route"),
              },
              {
                path: "points",
                lazy: () => import("../point/point-list/point-list.route"),
                loader: restoreSearchParamsLoader,
              },
              {
                path: "history",
                lazy: () =>
                  import("../point/point-history/point-history.route"),
              },
              {
                path: "devices/:deviceId/points/:pointId",
                lazy: () =>
                  import("../point/point-details/point-details.route"),
              },
              {
                path: "devices/:deviceId/points/:pointId/edit",
                lazy: () => import("../schedule/edit-point.route"),
              },
              // Point Groups
              {
                path: "pointgroups/create",
                lazy: () =>
                  import("../pointgroup/routes/point-group-create.route"),
              },
              // Alarms
              {
                path: "alarms",
                lazy: () => import("../alarm/alarm-list/alarm-list.route"),
                loader: (args) =>
                  restoreSearchParamsLoader({
                    ...args,
                    defaultParams:
                      "tab=active&sort=value.lastUpdate&order=desc",
                  }),
              },
              {
                path: "devices/:deviceId/alarms/:alarmId",
                lazy: () =>
                  import("../alarm/alarm-details/alarm-details.route"),
              },
              // Devices
              {
                path: `devices`,
                lazy: () => import("../device/device-list/device-list.route"),
                loader: restoreSearchParamsLoader,
              },
              // Graphics
              {
                path: "graphics",
                element: <GraphicsRoute />,
                children: [
                  {
                    path: "devices/:deviceId/menus",
                    lazy: () => import("../graphics/device-graphics-view"),
                  },
                  {
                    path: "levels/:levelId/menus",
                    lazy: () => import("../graphics/level-graphics-view"),
                  },
                ],
              },
              {
                path: "graphics/levels/:levelId/add-graphics",
                lazy: () => import("../graphics/add-level-graphics.route"),
              },
              /**
               * event and alarm logs
               */
              {
                path: "logs/:logType",
                lazy: () => import("../logs/log-list.route"),
              },

              /**
               * This catch-all route is rendered if none of the above routes match
               */
              {
                path: "*",
                lazy: () => import("./not-found"),
              },
            ],
          },
        ],
      },
      /**
       * This catch-all route is rendered if none of the above routes match
       */
      {
        path: "*",
        lazy: () => import("./not-found"),
      },
    ],
  },
]);
