import { currentEnv, AppEnv } from "./utils/consts";

export const config = {
  ENV: currentEnv as AppEnv,
  BASE_URL: import.meta.env.VITE_BASE_URL as string,
  API_URL: import.meta.env.VITE_API_URL as string,
  ADMIN_URL: import.meta.env.VITE_ADMIN_URL as string,
  ADMIN_API_URL: import.meta.env.VITE_ADMIN_API_URL as string,
  ASSETS_API_URL: import.meta.env.VITE_ASSETS_API_URL as string,
  BELINDA_HOSTNAME: import.meta.env.VITE_BELINDA_HOSTNAME as string,
} as const;

export type Config = typeof config;
