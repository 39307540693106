import {
  infiniteQueryOptions,
  queryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { useRequiredParams } from "~utils/url";
import { ApiResponse } from "~api/api-response.model";
import { useSuspenseQueryDeferred } from "~utils/query-hooks";
import { t } from "@lingui/macro";
import { assetsApiRequest } from "../api/api-request";
import { Alarm } from "./alarm.model";
import { AlarmTab } from "./alarm.utils";

export type AlarmCount = {
  count: {
    all: number;
    active: number;
    unacknowledged: number;
  };
};
type AlarmInfiteListParams = Omit<AlarmListParams, "page">;

type AlarmListParams = {
  companyId: number;
  tab?: AlarmTab;
  search?: string;
  sort?: string;
  order?: string;
  page: number;
  pageSize: number;
  alarmGroups?: string[];
  lockStates?: string[];
  deviceId?: number;
  levelId?: number;
};

type AlarmCountParams = Omit<
  AlarmListParams,
  "page" | "pageSize" | "sort" | "order"
>;

type AlarmDetailsParams = {
  companyId: number;
  deviceId: number;
  id: number;
};

type ExtraAlarmQueryOptions = Omit<typeof queryOptions, "queryKey" | "queryFn">;

export const alarmQueries = {
  infinite: (params: AlarmInfiteListParams) =>
    infiniteQueryOptions({
      queryKey: ["alarm-infinite-list", params],
      queryFn: ({ signal, pageParam }) =>
        fetchInfiniteAlarms({ ...params, pageParam }, signal),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPage.length === 0) {
          return undefined;
        }
        return lastPageParam + 1;
      },
    }),
  list: (params: AlarmListParams, options?: ExtraAlarmQueryOptions) =>
    queryOptions({
      queryKey: ["alarms", params],
      queryFn: () => fetchAlarms(params),
      ...options,
    }),
  details: (params: AlarmDetailsParams) =>
    queryOptions({
      queryKey: ["alarm-details", params],
      queryFn: () => fetchAlarm(params),
    }),
  count: (params: AlarmCountParams, options?: ExtraAlarmQueryOptions) =>
    queryOptions({
      queryKey: ["alarms-count", params],
      queryFn: () => fetchCount(params),
      ...options,
    }),
};

// Queries

export function useAlarmsInfiniteQuery(
  params: Omit<AlarmInfiteListParams, "companyId">,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useInfiniteQuery(alarmQueries.infinite({ companyId, ...params }));
}
export function useAlarmsCountQuery(
  params?: Omit<AlarmCountParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(alarmQueries.count({ companyId, ...params }, options));
}

export function useAlarmsQuery(
  params: Omit<AlarmListParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(alarmQueries.list({ companyId, ...params }, options));
}

export function useAlarmsSuspenseQuery(
  params: Omit<AlarmListParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    alarmQueries.list({ companyId, ...params }, options),
  );
}

export function useAlarmSuspenseQuery(
  params: Omit<AlarmDetailsParams, "companyId">,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    alarmQueries.details({ companyId, ...params }),
  );
}

// Fetchers
async function fetchInfiniteAlarms(
  {
    companyId,
    pageParam,
    alarmGroups,
    tab,
    ...params
  }: AlarmInfiteListParams & { pageParam: number },
  signal?: AbortSignal,
) {
  const actualGroups = alarmGroups?.map((g) =>
    g === t`NO GROUP` ? "null" : g,
  );

  return assetsApiRequest
    .get<ApiResponse<Alarm[]>>(`analytics/companies/${companyId}/alarms`, {
      // Get points from nested levels if levelId is provided
      params: {
        ...params,
        page: pageParam,
        alarmGroups: actualGroups,
        alarmType: tab,
        nested: params.levelId ? true : undefined,
      },
      signal,
    })
    .then((resp) => resp.data.data.map((d) => new Alarm(d)));
}

async function fetchAlarms({
  companyId,
  alarmGroups,
  tab,
  ...params
}: AlarmListParams) {
  // Replace NO GROUP with null for the query since its actual value in the DB is null. NO GROUP is just shown to the user
  const actualGroups = alarmGroups?.map((g) =>
    g === t`NO GROUP` ? "null" : g,
  );
  return await assetsApiRequest
    .get<
      ApiResponse<Alarm[]>
    >(`analytics/companies/${companyId}/alarms`, { params: { ...params, alarmGroups: actualGroups, nested: params.levelId ? true : undefined, alarmType: tab } })
    .then((resp) => resp.data.data.map((d) => new Alarm(d)));
}

async function fetchAlarm(params: AlarmDetailsParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Alarm>
    >(`analytics/companies/${params.companyId}/alarms/${params.id}`)
    .then((resp) => new Alarm(resp.data.data));
}

async function fetchCount({ companyId, ...params }: AlarmCountParams) {
  return assetsApiRequest
    .get<
      ApiResponse<AlarmCount>
    >(`analytics/companies/${companyId}/alarms/count`, { params })
    .then((resp) => resp.data.data);
}
