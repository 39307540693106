import { Graphics } from "../graphics/graphics.model";
import { Level } from "../level/level.model";
export enum DeviceStatus {
  Idle = 0,
  Connected = 1,
  Disconnected = 2,
  AuthFailure = 3,
  VersionFailure = 4,
  Timeout = 5,
  Disabled = 6,
}

export class Device {
  id: number;
  name: string;
  ipAddress: string;
  port: string;
  authKey: string;
  levelId: number;
  externalId: string;
  level?: Level;
  graphics: Graphics[];
  status?: DeviceStatus;

  constructor(device: Device) {
    this.id = device.id;
    this.name = device.name;
    this.ipAddress = device.ipAddress;
    this.port = device.port;
    this.authKey = device.authKey;
    this.levelId = device.levelId;
    this.externalId = device.externalId;
    this.level = device.level;
    this.graphics = device.graphics;
    this.status = device.status;
  }
}
